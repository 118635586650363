import { h } from 'preact';
import { Link } from 'preact-router/match';

const Footer = () => (
    <nav class="top-nav top-nav-inverse bg-black">
        <div class="container">
            <div class="row v-center">
                <div class="left col-md-6">
                    {/* <a href="tel:+0123456789" class="mr-15"><i class="fas fa-phone fa-rotate-90 mr-10"></i>+0123 456 789</a> */}
                    <a href="mailto:info@exiletoeden.com" class="nav-link d-inline-block"><i class="fas fa-envelope mr-10"></i>info@exiletoeden.com</a>
                </div>
                <div class="right col-md-6">
                    <a href="https://www.facebook.com/profile.php?id=100089350236798" class="nav-link mr-15 d-inline-block"><i class="fab fa-facebook-f"></i></a>
                    <a href="https://twitter.com/ExileToEden" class="nav-link mr-15 d-inline-block"><i class="fab fa-twitter"></i></a>
                    <a href="https://www.instagram.com/exile_to_eden/" class="nav-link d-inline-block"><i class="fab fa-instagram"></i></a>
                </div>
            </div>
        </div>
    </nav>
);

export default Footer;

