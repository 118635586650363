import { h } from 'preact';
import { Link } from 'preact-router/match';
import { useState, useEffect } from 'preact/hooks';

const Header = () => {
    const [isLiveEnvironment, setIsLiveEnvironment] = useState(false);

    useEffect(() => {
        console.log(window.location.href);
        if (window.location.href.startsWith('https://www.exiletoeden.com')) {
            setIsLiveEnvironment(true);
        }
    }, []);

    const onToggleMenu = () => {
        const nav = document.getElementById('navbar-toggle');
        nav.classList.toggle('collapse');
    }

    return (
        <nav class="navbar navbar-expand-lg navbar-inverse has-sticky-logo main-nav fixed-top bg-black">
            <div class="sticky-nav-activate fixed-top"></div>
            <div class="container">
                {/* <a class="navbar-brand" href="index.html"><img src="assets/images/logo.png" alt="" /></a>
            <a class="navbar-brand sticky-logo" href="index.html"><img src="assets/images/logo.png" alt="" /></a> */}
                <a href='/'><h3 class='mb-0'>Exile To Eden</h3></a>
                {!isLiveEnvironment &&
                    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-toggle" aria-controls="navbar-toggle" aria-expanded="false" aria-label="Toggle navigation" onClick={onToggleMenu}>
                        <span class="icon-bar top-bar"></span>
                        <span class="icon-bar middle-bar"></span>
                        <span class="icon-bar bottom-bar"></span>
                        <span class="sr-only">Toggle navigation</span>
                    </button>
                }

                {!isLiveEnvironment &&

                    <div class="collapse navbar-collapse" id="navbar-toggle">
                        <ul class="navbar-nav ml-auto d-none d-lg-flex">
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#x" id="dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <span class="va-middle">Design Tests</span></a>
                                <div class="dropdown-menu" aria-labelledby="dropdown">
                                    <a class="dropdown-item" href="/backgrounds"> <span>Backgrounds</span></a>
                                    <a class="dropdown-item" href="/level-up"> <span>Level Up Cards</span></a>
                                </div>
                            </li>
                        </ul>
                        <div class="navbar-nav ml-auto d-lg-none">
                            <a class="dropdown-item" href="/backgrounds" onClick={onToggleMenu}> <span>Backgrounds</span></a>
                            <a class="dropdown-item" href="/level-up" onClick={onToggleMenu}> <span>Level Up Cards</span></a>
                        </div>

                    </div>
                }
            </div>
        </nav>)
};

export default Header;
