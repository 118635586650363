import { h } from 'preact';
import { Router } from 'preact-router';
import Header from './header';

import animate from '/style/animate.css';
import fontawesome from '/style/fontawesome-all.min.css';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Profile from '../routes/profile';
import Footer from './footer';
import Backgrounds from '../routes/backgrounds';
import LevelUp from '../routes/level-up';

const App = () => (
	<div id="app">
		<Header />
		<main>
			<Router>
				<Home path="/" />
				<Backgrounds path="/backgrounds" />
				<LevelUp path="/level-up" />
				<Profile path="/profile/" user="me" />
			</Router>
		</main>
		<Footer />
	</div>
);

export default App;
